import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";

export default class extends Controller {
  static targets = ["uploadTemplate", "photos"];

  uploadFile = (file, url, name) => {
    const upload = new DirectUpload(file, url, this);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.error("Error uploading file", error);
      } else {
        // Add an appropriately-named hidden input to the form with a value of blob.signed_id so that the blob ids
        // will be transmitted in the normal upload flow.
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = name;

        // this.element.querySelector('form').appendChild(hiddenField);
        if (file.previewElement != undefined) {
          file.previewElement.appendChild(hiddenField);
        } else {
          console.debug("No preview element found for file", file);
        }
      }
    });
  };

  // Private
  hideFileInput(input) {
    // input.disabled = true;
    input.style.display = "none";

    input.addEventListener("change", (event) => {
      Array.from(event.target.files).forEach(file => this.uploadFile(file, input.dataset.directUploadUrl, input.name));
    });
  }

  connect() {
    const root = this.element;
    const fileInputField = root.querySelector('input[type="file"]');
    const url = fileInputField.dataset.directUploadUrl;
    const name = fileInputField.name;

    console.debug("Connecting Dropzone controller");
    this.hideFileInput(fileInputField);

    this.myDropzone = new Dropzone(root.querySelector("form"), {
      url,
      autoQueue: false,
      previewTemplate: this.uploadTemplateTarget.innerHTML,
      previewsContainer: this.photosTarget,
      drop: (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        Array.from(files).forEach(file => this.uploadFile(file, url, name))
      }
    });

    // this.myDropzone.on("addedfile", (file) => {
    //   console.debug("Added file", file)
    // });
  }

  disconnect() {
    this.myDropzone = null;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      this.directUploadDidProgress(event)
    });
  }

  directUploadDidProgress(event) {
    const root = this.element;
    root.querySelector('.progress .progress-bar').style.width = `${event.loaded * 100 / event.total}%`;
  }

}