import { Controller } from "@hotwired/stimulus"
// No need to import preline
// import * as preline from "preline"

export default class extends Controller {
  connect() {
    this.element.textContent = "Hello World from StimulusJS!"

    document.addEventListener('DOMContentLoaded', function() {
      // console.warn('HSOverlay', HSOverlay)
      // HSOverlay.open(document.getElementById('hs-basic-modal'))
    })
  }
}
