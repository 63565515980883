import { Controller } from "@hotwired/stimulus"

import HSSelect from "preline/dist/select";

// Connects to data-controller="bp-filters"
export default class extends Controller {
  connect() {
    console.debug("Hello from BPFiltersController!")

    setTimeout(() => {
      this.element.querySelectorAll("select").forEach((input) => {
        let instance = HSSelect.getInstance(input)
        instance.on('change', () => this.element.requestSubmit())
      })
    }, 200)

  }
}
