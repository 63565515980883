import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  // values
  static targets = []
  static values = { chart: Array }

  connect() {
    console.log('Connected to Perceived Brand Quality controller!')
    var chart = new ApexCharts(this.element, this.chartOptions);
    chart.render();
  }

  get chartData() {
    // let chartData = []
    let positive = []
    let neutral = []
    let negative = []

    this.chartValue.forEach(el => {
      positive.push(el.positive)
      neutral.push(el.neutral)
      negative.push(el.negative)
    })

    return [
      { "name": "Negative", "data": negative },
      { "name": "Neutral", "data": neutral },
      { "name": "Positive", "data": positive },
    ]
  }

  get chartLabels() {
    return this.chartValue.map(el => el.name)
  }

  get chartOptions() {
    return {
      series: this.chartData,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      // colors:['#F44336', '#E91E63', '#9C27B0'],
      // dataLabels: {
      //   enabled: false
      // },
      xaxis: {
        categories: this.chartLabels,
      },
      yaxis: {
        labels: {
          show: true
        }
      },
    }
  }

}
