import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

// Connects to data-controller="bpi"
export default class extends Controller {
  // values
  static targets = []
  static values = { chart: Array }

  connect() {
    var chart = new ApexCharts(this.element, this.chartOptions);
    chart.render();
  }

  get chartData() {
    let chartData = []
    this.chartValue.forEach(el => chartData.push(el.index))
    return chartData
  }

  get chartLabels() {
    let chartLabels = []
    this.chartValue.forEach(el => chartLabels.push(el.name))
    return chartLabels
  }

  get chartOptions() {
    return {
      series: [{
        name: 'Perception index',
        data: this.chartData
      }],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.chartLabels,
      },
      annotations: {
        xaxis: [{
          x: 100,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: '100 index',
          }
        }]
      }
    }
  }

}
