import { Controller } from "@hotwired/stimulus";
// No need to import preline
// import * as preline from "preline"
import Rails from "@rails/ujs";
import HSOverlay from "preline/dist/overlay";

export default class extends Controller {
  static targets = [
    "imageTemplate",
    "textTemplate",
    "instructionsModal",
    "instructionsNextButton",
    "question",
    "questionStats",
    "categoryStats",
    "brandButton",
    "brandsContainer"
  ];
  static values = {
    survey: Object,
    answeredQuestionIds: Array,
    answeredCategoryIds: Array,
    questionnaireUrl: String,
    finishAnsweringUrl: String,
    translations: Object,
  };

  connect() {
    this.remainingCategories = this.getRemainingCategories();
    this.currentCategory = this.getCategory(this.remainingCategories[0].id);
    this.remainingQuestions = this.getRemainingQuestions();

    document.addEventListener("turbo:load", () => {
      console.debug("turbo:load from survey_controller");

      this.showCategoryModal();
      this.nextQuestion();
      this.shuffleBrands();
    });
  }

  acceptInputs(state) {
    this.brandButtonTargets.forEach((button) => {
      // console.debug('acceptInputs', state)
      if (state === false) {
        button.classList.add("pointer-events-none");
      } else {
        button.classList.remove("pointer-events-none");
      }
      button.disabled = !state;
    });
  }

  answerQuestion(event) {
    event.preventDefault();
    this.acceptInputs(false);

    const question = this.currentQuestion;
    const ids = this.answeredQuestionIdsValue;
    ids.push(question.id);
    this.answeredQuestionIdsValue = ids;

    fetch("/answers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({
        answer: {
          category_id: this.currentCategory.id,
          question_type: this.getQuestionType(this.currentCategory),
          question_id: question.id,
          brand_id: event.currentTarget.dataset.brandId,
          survey_id: this.surveyValue.id,
        },
      }),
      credentials: "same-origin",
    }).then((response) => {
      if (response.ok) {
        this.nextQuestion();
      } else {
        throw new Error("Something went wrong");
      }
    });
  }

  getQuestionType(category) {
    switch (category.category_type) {
      case "PhotoCategory":
        return "PhotoQuestion";
      case "TextCategory":
        return "TextQuestion";
    }
  }

  nextQuestion() {
    if (this.remainingQuestions.length === 0) {
      const categoryIds = this.answeredCategoryIdsValue;
      categoryIds.push(this.currentCategory.id);
      this.answeredCategoryIdsValue = categoryIds;
      this.nextCategory();
    } else {
      let nextQuestion = this.remainingQuestions.pop();
      this.currentQuestion = nextQuestion;
      this.renderQuestion(nextQuestion);
    }
    this.renderStats();
  }

  renderOutOf(remaining, total) {
    return `<span class="text-sm text-slate-700 text-nowrap">${remaining} / ${total}</span>`;
  }

  statusLabel(name) {
    return `<span class="text-xs uppercase text-slate-500">${name}</span>`;
  }

  renderStats() {
    this.questionStatsTarget.innerHTML =
      this.statusLabel(this.translationsValue.question) +
      " " +
      this.renderOutOf(
        this.currentCategory.questions.length - this.remainingQuestions.length,
        this.currentCategory.questions.length,
      );
    this.categoryStatsTarget.innerHTML =
      this.statusLabel(this.translationsValue.category) +
      " " +
      this.renderOutOf(
        this.surveyValue.categories.length -
          this.remainingCategories.length +
          1,
        this.surveyValue.categories.length,
      );
  }

  nextCategory() {
    console.debug("nextCategory from", this.remainingCategories);
    let nextCategory = this.getRemainingCategories().shift();
    console.debug("nextCategory selected", nextCategory);
    this.acceptInputs(false);

    if (nextCategory) {
      fetch("/category_completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        body: JSON.stringify({
          category_completion: {
            category_id: this.currentCategory.id,
            survey_id: this.surveyValue.id,
          },
        }),
        credentials: "same-origin",
      }).then((response) => {
        if (response.ok) {
          this.currentCategory = nextCategory;
          this.remainingQuestions = this.getRemainingQuestions();
          this.remainingCategories = this.getRemainingCategories();
          this.showCategoryModal();
          this.nextQuestion();
        } else {
          throw new Error("Something went wrong with category completion");
        }
      });
    } else {
      console.debug("End of survey. Finished answering.");
      this.finishAnswering();
    }
  }

  finishAnswering() {
    // Submit finished_answering
    fetch(this.finishAnsweringUrlValue, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    }).then((response) => {
      if (response.ok) {
        this.gotoQuestionnaire();
      } else {
        throw new Error("Something went wrong");
      }
    });
  }

  gotoQuestionnaire() {
    console.debug("gotoQuestionnaire");
    window.location.href = this.questionnaireUrlValue;
  }

  showCategoryModal() {
    this.acceptInputs(false);

    if (this.instructionsModalTarget) {
      this.instructionsModalTarget.getElementsByClassName("category-title")[0].innerHTML = this.currentCategory.title;
      this.instructionsModalTarget.getElementsByClassName("category-instructions")[0].innerHTML = this.currentCategory.instructions;
      this.instructionsNextButtonTarget.innerHTML = this.translationsValue.continue || "Continue";

      let modal = HSOverlay.getInstance(this.instructionsModalTarget, true);
      modal.element.on("close", () => this.acceptInputs(true));
      HSOverlay.open(this.instructionsModalTarget);
    } else {
      console.debug("No instructions modal target");
    }
  }

  renderQuestion(question) {
    console.debug(
      "renderQuestion",
      question,
      this.currentCategory.category_type,
    );
    if (this.currentCategory.category_type === "PhotoCategory") {
      this.renderImageQuestion(question);
    } else {
      this.renderTextQuestion(question);
    }
    this.acceptInputs(true);
  }

  getCategory(id) {
    return this.surveyValue.categories.find((c) => c.id == id);
  }

  getQuestion(id) {
    return this.currentCategory.questions.find((q) => q.id == id);
  }

  renderImageQuestion(question) {
    let template = this.imageTemplateTarget.content.cloneNode(true);
    let imageEl = template.querySelector(".image");
    imageEl.src = question.photo;

    this.questionTarget.innerHTML = imageEl.outerHTML;
  }

  renderTextQuestion(question) {
    let template = this.textTemplateTarget.content.cloneNode(true);
    let textEl = template.querySelector(".text");
    textEl.innerHTML = question.content;

    this.questionTarget.innerHTML = textEl.outerHTML;
  }

  getRemainingCategories() {
    return this.surveyValue.categories.filter(
      (c) => !this.answeredCategoryIdsValue.includes(c.id),
    );
  }

  getRemainingQuestions() {
    let q = this.currentCategory.questions.filter(
      (q) => !this.answeredQuestionIdsValue.includes(q.id),
    );
    return this.shuffle(q);
  }

  shuffleBrands() {
    const container = this.brandsContainerTarget;
    const elements = this.brandButtonTargets;

    const shuffledElements = this.shuffle(Array.from(elements));
    const trashBrandIndex = shuffledElements.findIndex((element) => element.dataset.surveyTrashValue === "true");
    if (trashBrandIndex !== -1) {
      const trashBrand = shuffledElements.splice(trashBrandIndex, 1)[0];
      shuffledElements.push(trashBrand);
    }

    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
    shuffledElements.forEach((element) => {
      container.appendChild(element);
    });
  }

  shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }
}
