// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";

import "./controllers"

document.addEventListener('DOMContentLoaded', function () {
  console.log("Welcome to the BPS general part!");

  console.log('DOMContentLoaded event fired.');
})

document.addEventListener("turbo:render", (event) => {
  console.debug("turbo:render event fired", event);

  // Re-initialize Preline
  // HSStaticMethods.autoInit();
});

document.addEventListener("turbo:load", (event) => {
  console.debug("turbo:load event fired", event);

  // Re-initialize Preline
  HSStaticMethods.autoInit();

  console.debug("---------------");
});

// document.addEventListener("turbo:before-visit", () => {
//   console.debug("turbo:before-visit event fired");
// })
